import React, {useMemo} from "react"
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout"
import PhotoGrid from '../components/photogrid';
import SEO from "../components/seo";

const IndexPage = () => {
    const { config, images } = useStaticQuery(graphql`
        {
            config: configJson {
                homepageGallery
            }
            images: allFile(filter: {sourceInstanceName: {eq: "uploads"}}) {
              edges {
                node {
                  publicURL
                  base
                  childImageSharp {
                    fixed(width: 400, quality: 100) {
                        originalName
                        ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
        }
    `
    )

    const homepageImages = config.homepageGallery.map((ele) => {
        const split = ele.split("/")
        return split[split.length-1]
    })

    const matchedImages = useMemo(() => homepageImages.map(imageName => images.edges.find(image => image.node.base === imageName)), [images, homepageImages])

    return (
        <>
            <SEO/>
            <Layout>
                <PhotoGrid
                    images={matchedImages}
                />
            </Layout>
        </>
    );
}

export default IndexPage
