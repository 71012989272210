import React, { useState } from 'react';
import Img from "gatsby-image";
import Masonry from 'react-masonry-component';
import styled from 'styled-components';
import Carousel, { Modal, ModalGateway } from 'react-images';

const ClickContainer = styled.a`
    cursor: pointer;
`

const UnstyledGridElement = (props) => {
    if (!props.element.node.childImageSharp) {
        return null
    }
    const fixed = props.element.node.childImageSharp.fixed;
    return (
        <ClickContainer
            onClick={() => props.openImage(props.num)}
            className={props.className}
            key={fixed.originalName} 
        >
            <Img 
                style={{display: 'block'}}
                fixed={fixed}
            />
        </ClickContainer>
    );
}

const GridElement = styled(UnstyledGridElement)`
    margin: 0px;
`

const PhotoGrid = props => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [imageNum, setImageNum] = useState(0);
    const fullsize = props.images.map((ele) => {return {src: ele.node.publicURL}})
    
    function openImage(num) {
        setModalIsOpen(true);
        setImageNum(num)
    }

    return (
        <>
            <ModalGateway>
                {modalIsOpen ? (
                    <Modal onClose={() => setModalIsOpen(false)}>
                        <Carousel 
                            currentIndex={imageNum}
                            views={fullsize} 
                            styles={{
                                view: base => ({
                                    ...base,
                                    alignItems: 'center',
                                    display: 'flex ',
                                    height: 'calc(100vh - 54px)',
                                    justifyContent: 'center',

                                    '@media (min-width: 770px)': {
                                        padding: 20,
                                    },

                                    '& > img': {
                                        maxHeight: 'calc(100vh - 94px)',
                                    },
                                }),
                            }}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
            <Masonry
                options={{
                    fitWidth: true,
                }}
                disableImagesLoaded={false}
                updateOnEachImageLoad={false}
                imagesLoadedOptions={{}}
                style={{margin: '0 auto'}}
            >
                {props.images.map((element, num) => (
                    <GridElement 
                        num={num}
                        element={element}
                        openImage={openImage}
                    />
                ))}
            </Masonry>
        </>
    );
};

export default PhotoGrid;
